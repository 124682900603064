import figma from "../assets/img/ding/figma.png";
import procreate from "../assets/img/ding/procreate.png";

export const dataPropsES = {
  navbar: {
    home: "Inicio",
    about: "Sobre Nosotros",
    services: "Servicios",
    techstack: "Tecnología",
    team: "Equipo",
    contact: "Contactanos",
  },

  //Banner
  banner: {
    animationImg3: require("../assets/img/1.png"),
    animationImg2: require("../assets/img/2.png"),
    animationImg1: require("../assets/img/1.png"),
    animationImg4: require("../assets/img/5.png"),
    animationImg5: require("../assets/img/6.png"),
    toptitle: "Soluciones Comerciales",
    mainlefttitle: "din",
    mainspantitle: "g",
    mainrighttitle: "",
    content: "Aquí empieza el crecimiento de tu negocio...",
    leftbtn: "Bienvenid@",
    leftbtnlink: "#welcome",
    videoId: "vr0qNXmkUJ8",
    videobtn: "Watch Video",
  },

  sidenav: {
    logotext: ".Din",
    logotextspan: "g.",
    content:
      "¡Estamos contentos por tenerte de visita! Con Ding vas a encontrar el mejor lugar para explotar tus ideas. ¡Comunícate con nosotros!",
    locationbolt: "Cuyo 48, Local 4",
    locationnormal: "Charata, Chaco, Argentina",
    phonebolt: "+54 (3731) 455 614",
    phonenormal: "Llama sin compromiso",
    mailbolot: "info@dingtech.com.ar",
    mailnormal: "Soporte en línea 24/7",
    facebooklink: "https://www.facebook.com/Ding-Technology-100842759135964",
    twitterlink: "#",
    instagramlink: "https://www.instagram.com/dingtechnology/",
    linkedinlink: "#",
    pinterestlink: "#",
  },

  subscribe: {
    bold: "Únete a Ding!",
    regular: "Subscribete para recibir las mejores propuestas y noticias.",
    sendindMsg: "Enviando...",
    errorMsg: "¡Ups! No se pudo realizar la subscripción por un error.",
    successMsg: "¡Genial! ¡Ya estás subscrito!",
  },

  //Welcome
  welcome: {
    sectiontopTitle: "LA CREATIVIDAD NO TIENE LIMITES",
    sectionTitle: "Bienvenid@ a",
    sectionTitleSpan: "Ding",
    sectionContent:
      "En Ding Technology encontrarás un equipo comprometido y al servicio de tu emprendimiento.",
    sectionContent2: "¡Conoce lo que tenemos para ofrecerte!",
    welcomesData: [
      {
        icon: "icofont-magnet",
        title: "Asistencia personalizada",
        content:
          "Te ayudamos a potenciar tus ideas. Podrás interactuar directamente con el equipo de diseño y desarrollo.",
      },
      {
        icon: "icofont-laptop-alt",
        title: "Diseño, Desarrollo y Marketing",
        content:
          "Utilizamos tecnología de vanguardia para potenciar tus proyectos.",
      },
      {
        icon: "icofont-headphone-alt-2",
        title: "Soporte al instante",
        content:
          "Siempre atentos a tus necesidades. Vamos a estar 24hs para resolver cualquier inconveniente.",
      },
    ],
  },

  about: {
    skillToptitle: "Somos",
    skillToptitlespan: "Ding",
    skillTitle: "Tu Solución Comercial",
    skillTitlespan: "| Desarrollo, Diseño y Marketing",
    skillContent:
      "Un equipo con experiencia y compromiso estará para apoyarte en todo momento...",
    progressTitle1: "Web, Desktop & Mobile Development",
    progressValue1: "95%",
    progressTitle2: "UI/UX design",
    progressValue2: "95%",
    progressTitle3: "Campañas de Marketing",
    progressValue3: "95%",
    progressTitle4: "Graphic Design",
    progressValue4: "95%",

    item2topTitle: "Nuestro",
    item2topTitlespan: "Objetivo",
    item2Title: "Queremos lo Mejor Para Vos",
    item2Titlespan: "| Desarrollo Productivo",
    item2ContentTop:
      "Trabajamos para ayudar a emprendedores y empresas a crecer en su transformación digital.",
    item2ContentMiddle:
      "Logramos nuestras metas gracias a nuestra forma de trabajo, vamos desde el modelado de procesos, aplicando design thinking en las definiciones del proyecto hasta el uso de metodologías ágiles en el trabajo en equipo durante el desarrollo del proyecto.",
    item2ContentBottom:
      "Somos el aliado estratégico para encontrar las soluciones tecnológicas adecuadas.",

    item3TopTitle: "¿Por qué",
    item3TopTitleSpan: "Ding?",
    item3Title: "Porque En Ding",
    item3TitleSpan: "Pensamos En Tu Empresa",
    item3Content:
      "Nuestra mision es aportar soluciones tecnológicas reales y escalables en un mundo en constante cambio, con profesionales y colaboradores inmersos en una cultura organizacional que rescata el servicio personalizado como un valor característico y superlativo.",
    logo1: "icofont-finger-print",
    log1Title: "Retina Ready",
    logo2: "icofont-star",
    log2Title: "Perfect Design",
    logo3: "icofont-headphone-alt-2",
    log3Title: "Friendly Support",
    logo4: "icofont-laptop-alt",
    log4Title: "Unique Design",
    logo5: "icofont-rocket-alt-1",
    log5Title: "Expert Team",
    logo6: "icofont-ui-social-link",
    log6Title: "SEO & Marketing",
  },

  //Services
  services: {
    sectionTitle: "Nuestros",
    sectionTitleSpan: "Servicios",
    sectionContent: "Mira lo que tenemos en Ding Technology para ofrecerte...",
    animationImg: require("../../src/assets/img/vector-bg.png"),

    servicesData: [
      {
        icon: "icofont-pen-alt-3",
        title: "UX/UI Design",
        content:
          "En conjunto con el equipo de Graphic Design y Frontend development, buscamos la mejor experiencia de usuario con un diseño acorde a tu organización o necesidades. La mejor UX/UI para un manejo intuitivo, sencillo y agradable...",
        bgnumber: "1",
      },
      {
        icon: "icofont-laptop-alt",
        title: "Web Design",
        content:
          "En equipo pensamos en el éxito de tu proyecto. Partimos de tus ideas, diseñamos y construimos una plataforma especial para que tu contenido tenga un gran alcance y sea escalable. Te ofrecemos un diseño adaptable y full performance.",
        bgnumber: "2",
      },
      {
        icon: "icofont-tick-boxed",
        title: "Development",
        content:
          "Tu negocio puede crecer a través de nuestros servicios de desarrollo mobile, desktop o web. Juntos analizamos estrategias y planteamos un plan de trabajo ágil para que tu proyecto no deje de crecer." +
          `Mira nuestro stack de tecnologías.`,
        bgnumber: "4",
      },
      {
        icon: "icofont-chart-growth",
        title: "Marketing Digital",
        content:
          "Para generar ventas y tu proyecto crezca, te acompañamos durante todo el proceso de marketing digital. Te ofrecemos asesoramiento contínuo, diseño de campañas de marketing, redes sociales y creación de contenido. ",
        bgnumber: "5",
      },
    ],
  },

  team: {
    sectionTitle: "Nuestro",
    sectionTitleSpan: "Equipo",
    sectionContent:
      "Dicen por ahí que no es más importante la cantidad, sino la calidad.",
    animationimg1: require("../../src/assets/img/3.png"),
    animationimg2: require("../../src/assets/img/4.png"),
    animationimg3: require("../../src/assets/img/1.png"),
    animationimg4: require("../../src/assets/img/5.png"),
    teamsData: [
      {
        img: "https://fy-bucket-photos.s3.amazonaws.com/DSC04761-2.png",
        name: "Sil Mareco",
        profession: "Graphic Design, Social Media & Branding Desing ",
        socialicon1: "icofont-facebook",
        sociallink1: "https://www.facebook.com/silmrc/",
        socialicon2: "icofont-behance",
        sociallink2: "https://www.behance.net/silmareco",
        socialicon3: "icofont-instagram",
        sociallink3: "https://www.instagram.com/silmareco/",
        socialicon4: "icofont-linkedin",
        sociallink4: "https://www.linkedin.com/in/silmareco/",
      },
      {
        img: "https://fy-bucket-photos.s3.amazonaws.com/IMG_20211230_144444459_PORTRAIT.jpg",
        name: "Emmanuel",
        profession: "Web Desing, Frontend Developer, Marketing Manager",
        socialicon1: "icofont-facebook",
        sociallink1: "https://www.facebook.com/emmanuel.araujo.35/",
        socialicon2: "icofont-github",
        sociallink2: "https://github.com/EmmaMusica",
        socialicon3: "icofont-instagram",
        sociallink3: "https://www.instagram.com/emmaraujo.ea/",
        socialicon4: "icofont-linkedin",
        sociallink4: "https://www.linkedin.com/in/emmanuel-araujo-b15ba1208/",
      },
      {
        img: "https://fy-bucket-photos.s3.amazonaws.com/269486585_4056964877738854_9041011927434228010_n.jpg",
        name: "Sebastian",
        profession: "Devops, Qa, Backend Developer",
        socialicon1: "icofont-facebook",
        sociallink1: "https://www.facebook.com/sebasvsl",
        socialicon2: "icofont-github",
        sociallink2: "https://github.com/sebas-dev-lab",
        socialicon3: "icofont-instagram",
        sociallink3: "https://www.instagram.com/sebasvlescano",
        socialicon4: "icofont-linkedin",
        sociallink4: "https://www.linkedin.com/in/sebasvsl",
      },
    ],
  },

  features: {
    featuresImage: require("../../src/assets/img/features-img.jpg"),
    animationimg1: require("../../src/assets/img/3.png"),
    animationimg2: require("../../src/assets/img/4.png"),
    animationimg3: require("../../src/assets/img/1.png"),
    featuresData: [
      {
        icon: "icofont-magnet",
        title: "Asistencia",
        content:
          "Junto a vos trabajamos para hacer crecer tu negocio.",
      },
      {
        icon: "icofont-chart-bar-graph",
        title: "Nuestros procesos",
        content:
          "5 pasos: Análisis del proyecto; Planificación y Diseño; Desarrollo; Control de calidad; Entrega y puesta en marcha.",
      },
      {
        icon: "icofont-anchor",
        title: "Márketing, ventas y seguimiento",
        content:
          "Diseño de campaña de marketing digital, Branding y seguimiento.",
      },
    ],
  },

  work: {
    workTitle: "¿Necesitas nuestros servicios?",
    worksmallTitle1: "¿Quieres unirte a nosotros?",
    worksmallTitle2: "Comunicate y cuéntanos tu idea...",
    btnlink: "contact",
    btnName: "Contactar",
    animationimg1: require("../../src/assets/img/3.png"),
    animationimg2: require("../../src/assets/img/4.png"),
    animationimg3: require("../../src/assets/img/1.png"),
  },

  techStack: {

    contactussData: [
      {
        boxicon: "js",
        boxtitle: "Javascript",
        boxitem1link: "#",
        boxitem1: "MERN stack",
        boxitem2link: "#",
        boxitem2: "PERN stack",
      },
      {
        boxicon: "react",
        boxtitle: "React.js",
        boxitem1link: "#",
        boxitem1: "Atomic Design",
        boxitem2link: "#",
        boxitem2: "React Native",
      },
      {
        boxicon: "html5",
        boxtitle: "HTML5",
        boxitem1link: "#",
        boxitem1: "Buenas practicas",
        boxitem2link: "#",
        boxitem2: "Código Limpio",
      },
      {
        boxicon: "css3",
        boxtitle: "CSS3",
        boxitem1link: "#",
        boxitem1: "Experiencias UI/UX",
        boxitem2link: "#",
        boxitem2: "SASS & LESS",
      },
      {
        boxicon: "vuejs",
        boxtitle: "Vue.js",
        boxitem1link: "#",
        boxitem1: "La mejor tecnología para tus proyectos",
        boxitem2link: "#",
        boxitem2: "",
      },
      {
        boxicon: "node",
        boxtitle: "Node.js",
        boxitem1link: "#",
        boxitem1: "Rest API",
        boxitem2link: "#",
        boxitem2: "Software de escritorio con Electron",
      },
      {
        boxicon: "python",
        boxtitle: "Python",
        boxitem1link: "#",
        boxitem1: "Django & Flask",
        boxitem2link: "#",
        boxitem2: "Automatización y análisis de datos",
      },
      {
        boxicon: "java",
        boxtitle: "Java",
        boxitem1link: "#",
        boxitem1: "Rest API",
        boxitem2link: "#",
        boxitem2: "Spring Boot",
      },
      {
        boxicon: "docker",
        boxtitle: "Docker",
        boxitem1link: "#",
        boxitem1: "Despliega tu proyecto con docker",
        boxitem2link: "#",
        boxitem2: "Automatización con Kubernetes",
      },
      {
        boxicon: "aws",
        boxtitle: "AWS",
        boxitem1link: "#",
        boxitem1: "Despliega tu proyecto en la nube",
        boxitem2link: "#",
        boxitem2: "",
      },
      {
        boxicon: "wordpress",
        boxtitle: "Wordpress",
        boxitem1link: "#",
        boxitem1: "Woocommerce & Elementor",
        boxitem2link: "#",
        boxitem2: "Despliega de tu proyecto en nuestro hosting",
      },
    ],

  }
  ,

  designStack:{
    contactussData: [
      {
        boxicon: "icofont-brand-adobe",
        boxtitle: "Adobe Technology",
        boxitem1link: "#",
        boxitem1: "Photoshop, Ilustrator, Indesign",
        boxitem2link: "#",
        boxitem2: "Premiere Pro, Lightroom",
      },
      {
        boxicon: (() => {
          return (
            <img
              style={{
                width: '57px', 
                height: '57px',
                margin: '0 auto',
                marginTop: '5px'
              }}
              alt="svgImg"
              src={procreate}
            ></img>
          );
        })(),
        boxtitle: "Procreate",
        boxitem1link: "#",
        boxitem1: "El mejor arte digital",
        boxitem2link: "#",
        boxitem2: "",
      },
      {
        boxicon: (() => {
          return (
            <img
            style={{
                width: '57px', 
                height: '57px',
                margin: '0 auto',
                marginTop: '5px'
              }}
              alt="svgImg"
              src={figma}
            />
          );
        })(),
        boxtitle: "Figma",
        boxitem1link: "#",
        boxitem1: "Diseño UI/UX",
        boxitem2link: "#",
        boxitem2: "Mockups",
      },
    ],
  },


  contactUs: {
    sectionTitle: "Contacta a",
    sectionTitleSpan: "Ding",
    sectionContent:
      "Ponte en contacto con nosotros, deja volar tus ideas y haz realidad tus sueños.",
    title: "¡No esperes más!",
    content:
      "Dejanos tu mensaje. Puedes hechar un vistazo a nuestras redes sociales y así estaremos un poco más cerca.",
    fblink: "https://www.facebook.com/Ding-Technology-100842759135964",
    twitterlink: "#",
    instagramlink: "https://www.instagram.com/dingtechnology/",
    linkedinlink: "#",
    placeHolderName: "Nombre",
    errorMsgName: "Por favor ingresa tu nombre",
    placeHolderNote: "Escribe tu mensaje aquí...",
    errorMsgNote: "Agrega un mensaje",
    errorMsgEmail: "Por favor ingresa un email",
    buttonSend: "Enviar",
    successMsg: "¡Ya recibimos tu mensaje! Te responderemos a la brevedad.",
    contactussData: [
      {
        boxicon: "icofont-location-pin",
        boxtitle: "Dirección",
        boxitem1link: "#",
        boxitem1: "Cuyo 48, Local 4",
        boxitem2link: "#",
        boxitem2: "Charata, Chaco, Argentina",
      },
      {
        boxicon: "icofont-mail",
        boxtitle: "Email",
        boxitem1link: "#",
        boxitem1: "Info@dingtech.com.ar",
        boxitem2link: "#",
        boxitem2: "support@dingtech.com.ar",
      },
      {
        boxicon: "icofont-phone",
        boxtitle: "Teléfonos",
        boxitem1link: "#",
        boxitem1: "+54 3731 455614",
        boxitem2link: "#",
        boxitem2: "+54 3624 924514",
      },
    ],
  },

  footer: {
    copyrightText: "Copyright @ 2021 Ding Technology. All rights reserved",
    footercopyrightLink1: "#",
    footercopyrightText1: "Términos & Condiciones",
    footercopyrightLink2: "#",
    footercopyrightText2: "Política de Privacidad",
  },
};
