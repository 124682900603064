import React, { Component } from 'react';
import Icofont from 'react-icofont';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { envs } from '../config/_envs';

const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value
        });

    return (
        <div className="newsletter-form">
            <input
                ref={node => (email = node)}
                type="email"
                placeholder="correo@gmail.com"
                className="form-control"
                required
            />

            <button onClick={submit}>
                <Icofont icon="send-mail"/>
            </button>

            {status === "sending" && (
                <div className="subs-sending-msg">{ message.sendindMsg }</div>
            )}
            {status === "error" && (
                <div
                    className="subs-error-msg"
                    dangerouslySetInnerHTML={{ __html: message.errorMsg }}
                />
            )}
            {status === "success" && (
                <div
                    className="subs-success-msg"
                    dangerouslySetInnerHTML={{ __html: message.successMsg }}
                />
            )}
        </div>
    );
};

class Subscribe extends Component {
    state = {};
    render() {
        
        return(
            <>
                <div className="subscribe-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 col-sm-12">
                                <p><b>{this.props.bold}</b> {this.props.regular}</p>
                            </div>
                            
                            <div className="col-lg-5 col-md-12 col-sm-12">
                                <MailchimpSubscribe
                                    url={envs.urlMailchimp}
                                    render={({
                                        subscribe,
                                        status
                                    }) => (
                                        <CustomForm
                                            
                                            status={status}
                                            message={this.props}
                                            onValidated={formData =>
                                                subscribe(formData)
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Subscribe;


