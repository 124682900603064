import figma from "../assets/img/ding/figma.png";
import procreate from "../assets/img/ding/procreate.png";


export const dataPropsEN = {

    navbar: {
        home: "Home",
        about: "About Us",
        services: "Services",
        techstack: "Technology",
        team: "Team",
        contact: "Contact Us"
    },

    //Banner
    banner: {
        animationImg3: require("../assets/img/1.png"),
        animationImg2: require("../assets/img/2.png"),
        animationImg1: require("../assets/img/1.png"),
        animationImg4: require("../assets/img/5.png"),
        animationImg5: require("../assets/img/6.png"),
        toptitle: "Business Solutions",
        mainlefttitle: "din",
        mainspantitle: "g",
        mainrighttitle: "",
        content:
        "Technology",
        leftbtn: "Welcome",
        leftbtnlink: "#welcome",
        videoId: "vr0qNXmkUJ8",
        videobtn: "Watch Video",  
    },
 
    sidenav: {
        logotext: ".Din",
        logotextspan: "g.",
        content: "We are happy to have you visit! With Ding you will find the best place to exploit your ideas. Contact us!",
        locationbolt: "Cuyo 48, Local 4",
        locationnormal: "Charata, Chaco, Argentina",
        phonebolt: "+54 (3731) 455 614",
        phonenormal: "Call without obligation",
        mailbolot: "info@dingtech.com.ar",
        mailnormal: "24/7 online support",
        facebooklink: "https://www.facebook.com/Ding-Technology-100842759135964",
        twitterlink: "#",
        instagramlink: "https://www.instagram.com/dingtechnology/",
        linkedinlink: "#",
        pinterestlink: "#",
    },

    subscribe:{
        bold: 'Join Ding!',
        regular: 'Subscribe to receive the best proposals and news.',
        sendindMsg: 'Sending...',
        errorMsg: 'Whoops! The subscription could not be made due to an error.',
        successMsg: 'Cool! You are already subscribed!'
    },

    //Welcome
    welcome: {
        sectiontopTitle: "CREATIVITY HAS NO LIMITS",
        sectionTitle: "Welcome to",
        sectionTitleSpan: "Ding",
        sectionContent: `We are a development company.`,
        sectionContent2: 'We work as a team to evolve your ideas into useful products for your company and your customers',
            welcomesData: [
            {
                icon: "icofont-magnet",
                title: "Custom assistance",
                content:
                    "We promote your idea with you. You will have direct contact to ask us for everything you need."
            },
            {
                icon: "icofont-laptop-alt",
                title: "Design, Development and Marketing",
                content:
                    "We use the best technology to focus on growing your company in the shortest possible time"
            },
            {
                icon: "icofont-headphone-alt-2",
                title: "Instant support",
                content:
                    "Always attentive to your needs. We will be 24hs to solve any problem"
            }
        ]
    },

    about:{
        skillToptitle: "We are",
        skillToptitlespan: "Ding",
        skillTitle: "Your Business Solution",
        skillTitlespan: "| Development & Marketing",
        skillContent: "We focus on growing your business with the best tools. Web, Desktop, Mobile and Marketing Development",
        progressTitle1: "Web Development, Desktop & Mobile",
        progressValue1: "95%",
        progressTitle2: "Marketing",
        progressValue2: "95%",
        progressTitle3: "Web Design",
        progressValue3: "95%",
        progressTitle4: "Branding",
        progressValue4: "95%",

    
        item2topTitle: "Our",
        item2topTitlespan: "Objetive",
        item2Title: "We Want The Best For You",
        item2Titlespan: "| Productive Development",
        item2ContentTop: "Ding Technology was born with the initiative to integrate all companies, businesses and merchants to the gigantic World Wide Web.",
        item2ContentMiddle: "We believe that equal opportunities contribute to the collective growth of all of us who are part of the socio-commercial system.",
        item2ContentBottom: "We define a goal, and achieving it is essential. There is no fight to victory, there is only one direct way to get there. We are a company that grows when your goal is met.",

    
        item3TopTitle: "Why",
        item3TopTitleSpan: "Ding?",
        item3Title: "Because At Ding",
        item3TitleSpan: "We Think About Your Company",
        item3Content: "We work to accompany you in your goal, your needs and to facilitate your heavy work.",
        logo1: "icofont-finger-print",
        log1Title: "Retina Ready",
        logo2: "icofont-star",
        log2Title: "Perfect Design",
        logo3: "icofont-headphone-alt-2",
        log3Title: "Friendly Support",
        logo4: "icofont-laptop-alt",
        log4Title: "Unique Design",
        logo5: "icofont-rocket-alt-1",
        log5Title: "Expert Team",
        logo6: "icofont-ui-social-link",
        log6Title: "SEO & Marketing"
    },


    //Services
    services: {
        sectionTitle: "Our",
        sectionTitleSpan: "Services",
        sectionContent: "we know exactly what you need",
        animationImg: require("../../src/assets/img/vector-bg.png"),
    
        servicesData: [
            {
                icon: "icofont-pen-alt-3",
                title: "UX Design",
                content: "We are experts in UX, we treat your design and transform it to seek user satisfaction. The best UX for an intuitive, simple and pleasant handling of your application.",
                bgnumber: "1"
            },
            {
                icon: "icofont-chart-growth",
                title: "Marketing",
                content: "We use everything available to grow your business. Thanks to the work of our team and the powerful web tools of today, your company will be located quickly and exposed to all audiences.",
                bgnumber: "2"
            },
            {
                icon: "icofont-laptop-alt",
                title: "Web Design",
                content: "Don't have your website? We build it for you! We think of your ideas as an initial part and we build a special website for your content. Adaptable design and full performance. Your website will be amazing!",
                bgnumber: "3"
            },
            {
                icon: "icofont-tick-boxed",
                title: "Development",
                content: "Development of web, desktop and mobile applications. We are careful with everything we do and that is why we focus on working with the latest technologies to prepare your quality system. A simple code, clean and easy to transfer.",
                bgnumber: "4"
            },
        ]
    },

    team: {
        sectionTitle: "Our",
        sectionTitleSpan: "Team",
        sectionContent: "They say that quantity is not more important, but quality.",
        animationimg1: require("../../src/assets/img/3.png"),
        animationimg2: require("../../src/assets/img/4.png"),
        animationimg3: require("../../src/assets/img/1.png"),
        animationimg4: require("../../src/assets/img/5.png"),
        teamsData: [
          {
            img: "https://fy-bucket-photos.s3.amazonaws.com/DSC04761-2.png",
            name: "Sil Mareco",
            profession: "Graphic Design, Social Media & Branding Desing ",
            socialicon1: "icofont-facebook",
            sociallink1: "https://www.facebook.com/silmrc/",
            socialicon2: "icofont-behance",
            sociallink2: "https://www.behance.net/silmareco",
            socialicon3: "icofont-instagram",
            sociallink3: "https://www.instagram.com/silmareco/",
            socialicon4: "icofont-linkedin",
            sociallink4: "https://www.linkedin.com/in/silmareco/",
          },
            {
                img: "https://fy-bucket-photos.s3.amazonaws.com/IMG_20211230_144444459_PORTRAIT.jpg",
                name: "Emmanuel",
                profession: "Web Desing, Frontend Developer, Marketing Manager",
                socialicon1: "icofont-facebook",
                sociallink1: "https://www.facebook.com/emmanuel.araujo.35/",
                socialicon2: "icofont-github",
                sociallink2: "https://github.com/EmmaMusica",
                socialicon3: "icofont-instagram",
                sociallink3: "https://www.instagram.com/emmaraujo.ea/",
                socialicon4: "icofont-linkedin",
                sociallink4: "https://www.linkedin.com/in/emmanuel-araujo-b15ba1208/",
            },
            {
                img: "https://fy-bucket-photos.s3.amazonaws.com/269486585_4056964877738854_9041011927434228010_n.jpg",
                name: "Sebastian",
                profession: "Devops, Qa, Backend Developer, Markenting",
                socialicon1: "icofont-facebook",
                sociallink1: "https://www.facebook.com/sebasvsl",
                socialicon2: "icofont-github",
                sociallink2: "https://github.com/sebas-dev-lab",
                socialicon3: "icofont-instagram",
                sociallink3: "https://www.instagram.com/sebasvlescano",
                socialicon4: "icofont-linkedin",
                sociallink4: "https://www.linkedin.com/in/sebasvsl",
            },
           ]
    },

    features: {
        featuresImage: require("../../src/assets/img/features-img.jpg"),
        animationimg1: require("../../src/assets/img/3.png"),
        animationimg2: require("../../src/assets/img/4.png"),
        animationimg3: require("../../src/assets/img/1.png"),
        featuresData: [
            {
                icon: "icofont-magnet",
                title: "Ideal Assistance",
                content:
                    "Together with you we work to make your business grow"
                },
            {
                icon: "icofont-chart-bar-graph",
                title: "Digital Marketing",
                content:
                    "Wide reach, maximum and focused audience. Your product reaches exactly the people who need it"
                },
            {
                icon: "icofont-anchor",
                title: "Unique and Original Design",
                content:
                    "Unique and original design adaptable to all devices. Clean code and easy to transfer."
            }
        ]
    },


    work: {
        workTitle: "Do you need our services?",
        worksmallTitle1: "Do you want to join us?",
        worksmallTitle2: "Communicate and tell us your idea...",
        btnlink: "contact",
        btnName: "Contact Us",
        animationimg1: require("../../src/assets/img/3.png"),
        animationimg2: require("../../src/assets/img/4.png"),
        animationimg3: require("../../src/assets/img/1.png")
    },

    techStack: {
  
      contactussData: [
        {
          boxicon: "js",
          boxtitle: "Javascript",
          boxitem1link: "#",
          boxitem1: "MERN stack",
          boxitem2link: "#",
          boxitem2: "PERN stack",
        },
        {
          boxicon: "react",
          boxtitle: "React.js",
          boxitem1link: "#",
          boxitem1: "Atomic Design",
          boxitem2link: "#",
          boxitem2: "React Native",
        },
        {
          boxicon: "html5",
          boxtitle: "HTML5",
          boxitem1link: "#",
          boxitem1: "Best practices",
          boxitem2link: "#",
          boxitem2: "Clean Code",
        },
        {
          boxicon: "css3",
          boxtitle: "CSS3",
          boxitem1link: "#",
          boxitem1: "UI/UX Experience",
          boxitem2link: "#",
          boxitem2: "SASS & LESS",
        },
        {
          boxicon: "vuejs",
          boxtitle: "Vue.js",
          boxitem1link: "#",
          boxitem1: "The best technology for your projects",
          boxitem2link: "#",
          boxitem2: "",
        },
        {
          boxicon: "node",
          boxtitle: "Node.js",
          boxitem1link: "#",
          boxitem1: "Rest API",
          boxitem2link: "#",
          boxitem2: "Desktop software with Electron",
        },
        {
          boxicon: "python",
          boxtitle: "Python",
          boxitem1link: "#",
          boxitem1: "Django & Flask",
          boxitem2link: "#",
          boxitem2: "Data analytics & Automation software",
        },
        {
          boxicon: "java",
          boxtitle: "Java",
          boxitem1link: "#",
          boxitem1: "Rest API",
          boxitem2link: "#",
          boxitem2: "Spring Boot",
        },
        {
          boxicon: "docker",
          boxtitle: "Docker",
          boxitem1link: "#",
          boxitem1: "Deploy your proyect with docker",
          boxitem2link: "#",
          boxitem2: "Deploy automation with Kubernetes",
        },
        {
          boxicon: "aws",
          boxtitle: "AWS",
          boxitem1link: "#",
          boxitem1: "Deploy your project in the cloud",
          boxitem2link: "#",
          boxitem2: "",
        },
        {
          boxicon: "wordpress",
          boxtitle: "Wordpress",
          boxitem1link: "#",
          boxitem1: "Woocommerce & Elementor",
          boxitem2link: "#",
          boxitem2: "Deploy your proyect with our hosting",
        },
      ],
  
    }
    ,
    designStack:{
      contactussData: [
        {
          boxicon: "icofont-brand-adobe",
          boxtitle: "Adobe Technology",
          boxitem1link: "#",
          boxitem1: "Photoshop, Ilustrator, Indesign",
          boxitem2link: "#",
          boxitem2: "Premiere Pro, Lightroom",
        },
        {
          boxicon: (() => {
            return (
              <img
                style={{
                  width: '57px', 
                  height: '57px',
                  margin: '0 auto',
                  marginTop: '5px'
                }}
                alt="svgImg"
                src={procreate}
              ></img>
            );
          })(),
          boxtitle: "Procreate",
          boxitem1link: "#",
          boxitem1: "El mejor arte digital",
          boxitem2link: "#",
          boxitem2: "",
        },
        {
          boxicon: (() => {
            return (
              <img
              style={{
                  width: '57px', 
                  height: '57px',
                  margin: '0 auto',
                  marginTop: '5px'
                }}
                alt="svgImg"
                src={figma}
              />
            );
          })(),
          boxtitle: "Figma",
          boxitem1link: "#",
          boxitem1: "Diseño UI/UX",
          boxitem2link: "#",
          boxitem2: "Mockups",
        },
      ],
    },


    contactUs: {
        sectionTitle: "Contact",
        sectionTitleSpan: "Ding",
        sectionContent: "Get in touch with us, let your ideas fly and make your dreams come true.",
        title: "Do not wait more!",
        content: "Leave us your message. You can take a look at our social networks and so we will be a little closer.",
        fblink: "https://www.facebook.com/Ding-Technology-100842759135964",
        twitterlink: "#",
        instagramlink: "https://www.instagram.com/dingtechnology/",
        linkedinlink: "#",
        placeHolderName: "Name",
        errorMsgName: "Please enter your name",
        placeHolderNote: "Write your message...",
        errorMsgNote: "Add a message",
        errorMsgEmail: "Please enter an email",
        buttonSend: "Send",
        successMsg: "We already received your message! We will respond to you shortly.",
        contactussData:[
            {
                boxicon: "icofont-location-pin",
                boxtitle: "Adress",
                boxitem1link: "#",
                boxitem1: "Cuyo 48, Local 4",
                boxitem2link: "#",
                boxitem2: "Charata, Chaco, Argentina",
    
            },
            {
                boxicon: "icofont-mail",
                boxtitle: "Email",
                boxitem1link: "#",
                boxitem1: "Info@dingtech.com.ar",
                boxitem2link: "#",
                boxitem2: "support@dingtech.com.ar",
    
            },
            {
                boxicon: "icofont-phone",
                boxtitle: "Phone Numbers",
                boxitem1link: "#",
                boxitem1: "+54 3731 455614",
                boxitem2link: "#",
                boxitem2: "+54 3624 924514",
    
            }
        ]
    },


    footer: {
        copyrightText: "Copyright @ 2021 Ding Technology. All rights reserved",
        footercopyrightLink1: "#",
        footercopyrightText1: "Terms & Conditions",
        footercopyrightLink2: "#",
        footercopyrightText2: "Privacy Policy"
    },



};