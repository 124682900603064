import React from "react";
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";
import figma from "../assets/img/ding/figma.png";
import procreate from "../assets/img/ding/procreate.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fb } from "@fortawesome/free-brands-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

const DesignStack = (props) => {
  const contactusdata = props.contactussData.map((contactus, index) => (
    <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
      <div className="contact-box-tech">
        <div className="icon">
          {contactus.boxtitle === "Procreate" ||
          contactus.boxtitle === "Figma" ? (
            contactus.boxicon
          ) : (
            <i className={contactus.boxicon} />
          )}
        </div>
        <h4>{contactus.boxtitle}</h4>
        <p>
          <a href={contactus.boxitem1link}>{contactus.boxitem1}</a>
        </p>
        <p>
          <a href={contactus.boxitem2link}>{contactus.boxitem2}</a>
        </p>
      </div>
    </div>
  ));

  return (
    <section className="contact-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>
            {props.sectionTitle} <b>{props.sectionTitleSpan}</b>
          </h2>
          <p>{props.sectionContent}</p>
        </div>

        <div className="row justify-content-center">
          <OwlCarousel
            className="feedback-slides owl-theme"
            loop={true}
            autoplay={true}
            nav={false}
            mouseDrag={true}
            autoplayHoverPause={true}
            responsiveClass={true}
            dots={true}
            navText={[
              "<i class='icon icon-Arrow'></i>",
              "<i class='icon icon-Arrow'></i>",
            ]}
            responsive={{
              0: {
                items: 1,
              },
              768: {
                items: 2,
              },
              1200: {
                items: 3,
              },
            }}
          >
            {contactusdata}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

DesignStack.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  feadbacksData: PropTypes.array,
  contactussData: PropTypes.array,
};
DesignStack.defaultProps = {
  sectionTitle: "Design",
  sectionTitleSpan: "Stack",
  sectionContent: "Conoce nuestro stack de tecnologías de diseño.",
  feadbacksData: [
    {
      image: require("../../src/assets/img/client-avatar1.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
    {
      image: require("../../src/assets/img/client-avatar2.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
    {
      image: require("../../src/assets/img/client-avatar3.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
    {
      image: require("../../src/assets/img/client-avatar3.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
  ],

  contactussData: [
    {
      boxicon: "icofont-brand-adobe",
      boxtitle: "Adobe Technology",
      boxitem1link: "#",
      boxitem1: "Photoshop, Ilustrator, Indesign",
      boxitem2link: "#",
      boxitem2: "Premiere Pro, Lightroom",
    },
    {
      boxicon: (() => {
        return (
          <img
            alt="svgImg"
            src={procreate}
          />
        );
      })(),
      boxtitle: "Procreate",
      boxitem1link: "#",
      boxitem1: "The best digital art",
      boxitem2link: "#",
      boxitem2: "",
    },
    {
      boxicon: (() => {
        return (
          <img
            alt="svgImg"
            src={figma}
          />
        );
      })(),
      boxtitle: "Figma",
      boxitem1link: "#",
      boxitem1: "UI/UX Design",
      boxitem2link: "#",
      boxitem2: "Mockups",
    },
  ],
};

export default DesignStack;
