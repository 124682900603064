import React, { Component } from "react";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import SideNav from  "../components/SideNav";
// import logoSmall from "../assets/img/ding/logoSmall.png"
// import logo from "../assets/img/ding/LogoDing.png"
import logo from "../assets/img/ding/logoNavbar.png"
import { setInLocalStorage } from "../helpers/resources_fns";





class NavBar extends Component {
    componentDidMount() {
        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("is-sticky");
            } else {
                elem.classList.remove("is-sticky");
            }
        });
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        this.setState({ scrollWithOffset });
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    verifyLngBtn(){
        let lngBtn;
        (this.props.i18n.language === 'es') ? lngBtn = 'en' : lngBtn = 'es' 
        return lngBtn 
    }

    handleClickLanguage(){
        setInLocalStorage('lastLng', this.verifyLngBtn())
        this.props.i18n.changeLanguage(this.verifyLngBtn())
    }

    render() {
        
        return (
            <>
                <Navbar
                    sticky="top"
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-lg navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                        <Navbar.Brand >
                            <Link
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={800}
                                className="d-flex align-items-center"
                                id="logoDing"
                            >
                                <div 
                                    py={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={800}
                                    id="logotipo"
                                >
                                    <img src={ logo } />
                                </div>
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn" 
                        />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="home"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        {this.props.navbar.home}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                       {this.props.navbar.about}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="services"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        {this.props.navbar.services}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="team"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        {this.props.navbar.team}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="techstack"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        {this.props.navbar.techstack}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        {this.props.navbar.contact}
                                    </Link>
                                </Nav.Item>
                                
                                <Nav.Item>
                                    {/* Button to change language */}
                                    <div  
                                        className='changeLanguageButton' 
                                        onClick={ () => this.handleClickLanguage() }
                                    >
                                       {this.verifyLngBtn().toUpperCase()}
                                    </div>
                                </Nav.Item>
                                    
                                <Nav.Item>
                                    {/* SideNav: src/components/SideNav */}
                                    <SideNav { ...this.props.sidenav } />
                                </Nav.Item>
                            </Nav> 
                        </Navbar.Collapse>
                    </Container>
                </Navbar> 
            </> 
        );
    }
}

export default NavBar;
