import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route} from 'react-router-dom';
import { i18n } from'./config/i18n';
import App from './App';
// import i18next from 'i18next';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

i18n()
library.add(fab, faCheckSquare, faCoffee)
ReactDOM.render(
    <BrowserRouter basename={"/tf-jsx-demo/exolot/"}>
        {/* <I18nextProvider i18n= {i18n}> */}
            <Route path="/" component={App} />
        {/* </I18nextProvider> */}
    </BrowserRouter>, 
document.getElementById('root'));