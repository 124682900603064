import React from "react";
import { Route, Switch } from "react-router-dom";
import Page from "react-page-loading";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";
// import { MessengerChat } from 'react-messenger-chat-plugin';
import MessengerCustomerChat from "react-messenger-customer-chat";

// Template CSS Style
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// For RTL Style Comment Out The Below rtl.css
// import "./assets/css/rtl.css";

//Component Import
import Ding from "./pages/Ding";

const App = () => {
  return (
    <div className="App">
      <div>
        <Page loader={"bubble-spin"} color={"#4f4093"} size={9}>
          <Switch>
            <Route path="/" component={Ding} />
          </Switch>
        </Page>
        <MessengerCustomerChat
          pageId="100842759135964"
          appId="1112167782934241"
        />
      </div>
    </div>
  );
};
export default App;
