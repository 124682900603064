import React from "react";

//Components List
import NavBar from "../dingComponents/NavBar";
import Subscribe from "../dingComponents/Subscribe";
import BannerOne from "../dingComponents/banner/BannerOne";
import Welcome from "../dingComponents/Welcome";
import About from "../dingComponents/About";
import Services from "../dingComponents/Services";
import Team from "../dingComponents/Team";
import Features from "../dingComponents/Features";
import Work from "../dingComponents/Work";
import ContactUs from "../dingComponents/ContactUs";
import Footer from "../dingComponents/Footer";
import ScrollUpBtn from "../dingComponents/ScrollUpBtn";
import { useTranslation } from "react-i18next";
import { dataPropsES } from "../mocks/dataES";
import { dataPropsEN } from "../mocks/dataEN";
import { getItemFromLocalStorage } from "../helpers/resources_fns";
import Portfolio from "../components/Portfolio";
import Feadback from "../components/Feadback";
import News from "../components/News";
import TechStack from "../dingComponents/TechStack";
import DesignStack from "../dingComponents/DesignStack";

const HomeThree = () => {
  let propsText;
  const { i18n } = useTranslation("global");

  i18n.language = getItemFromLocalStorage("lastLng")
    ? getItemFromLocalStorage("lastLng")
    : "es";

  i18n.language === "es"
    ? (propsText = { ...dataPropsES })
    : (propsText = { ...dataPropsEN });

  return (
    <>
      {/* NavBar: src/components/NavBar */}
      <NavBar i18n={i18n} {...propsText} />

      {/* BannerThree: src/components/banner/BannerThree */}
      <BannerOne {...propsText.banner} />

 

      {/* Welcome: src/components/Welcome */}
      <Welcome {...propsText.welcome} />

      {/* About: src/components/About */}
      <About {...propsText.about} />

      {/* Subscribe: src/components/Subscribe */}
      <Subscribe {...propsText.subscribe} />

      {/* Services: src/components/Services */}
      <Services {...propsText.services} />

      {/* Team: src/components/Team */}
      <Team {...propsText.team} />

      {/* Features: src/components/Features */}
      <Features {...propsText.features} />

      {/* Feadback: src/components/Feadback */}
      {/* <Feadback /> */}
      <TechStack {...propsText.techStack}/>
      {/* Pricing: src/components/Pricing */}
      {/* <Pricing /> */}

      <DesignStack {...propsText.designStack} />
      {/* Portfolio: src/components/Portfolio */}
      {/* <Portfolio /> */}

      {/* News: src/components/News */}
      {/* <News /> */}
      
      {/* Work: src/components/Work */}
      <Work {...propsText.work} />

      

      {/* ContactUs: src/components/ContactUs */}
      <ContactUs {...propsText.contactUs} />

      {/* Footer: src/components/Footer */}
      <Footer {...propsText.footer} />

      {/* ScrollUpBtn: src/components/ScrollUpBtn */}
      <ScrollUpBtn />
    </>
  );
};
export default HomeThree;
