import React from "react";
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fb } from "@fortawesome/free-brands-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

const TechStack = (props) => {
  const contactusdata = props.contactussData.map((contactus, index) => (
    <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
      <div className="contact-box-tech">
        <div className="icon">
          <FontAwesomeIcon icon={["fab", contactus.boxicon]} />
        </div>
        <h4>{contactus.boxtitle}</h4>
        <p>
          <a href={contactus.boxitem1link}>{contactus.boxitem1}</a>
        </p>
        <p>
          <a href={contactus.boxitem2link}>{contactus.boxitem2}</a>
        </p>
      </div>
    </div>
  ));


  return (
    <section className="contact-area ptb-100" id="techstack">
      <div className="container">
        <div className="section-title">
          <h2>
            {props.sectionTitle} <b>{props.sectionTitleSpan}</b>
          </h2>
          <p>{props.sectionContent}</p>
        </div>

        <div className="row justify-content-center">
          <OwlCarousel
            className="feedback-slides owl-theme"
            loop={true}
            autoplay={true}
            nav={false}
            mouseDrag={true}
            autoplayHoverPause={true}
            responsiveClass={true}
            dots={true}
            navText={[
              "<i class='icon icon-Arrow'></i>",
              "<i class='icon icon-Arrow'></i>",
            ]}
            responsive={{
              0: {
                items: 1,
              },
              768: {
                items: 2,
              },
              1200: {
                items: 3,
              },
            }}
          >
            {contactusdata}
          </OwlCarousel>
          
        </div>
      </div>
    </section>
  );
};

TechStack.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  feadbacksData: PropTypes.array,
  contactussData: PropTypes.array,
};
TechStack.defaultProps = {
  sectionTitle: "Tech",
  sectionTitleSpan: "Stack",
  sectionContent: "Conoce nuestro stack de tecnologías.",
  feadbacksData: [
    {
      image: require("../../src/assets/img/client-avatar1.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
    {
      image: require("../../src/assets/img/client-avatar2.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
    {
      image: require("../../src/assets/img/client-avatar3.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
    {
      image: require("../../src/assets/img/client-avatar3.jpg"),
      name: "Josh Buttler",
      profession: "Web Developer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    },
  ],

  contactussData: [
    {
      boxicon: "js",
      boxtitle: "Javascript",
      boxitem1link: "#",
      boxitem1: "MERN stack",
      boxitem2link: "#",
      boxitem2: "PERN stack",
    },
    {
      boxicon: "react",
      boxtitle: "React.js",
      boxitem1link: "#",
      boxitem1: "Atomic Design",
      boxitem2link: "#",
      boxitem2: "React Native",
    },
    {
      boxicon: "html5",
      boxtitle: "HTML5",
      boxitem1link: "#",
      boxitem1: "Best practices",
      boxitem2link: "#",
      boxitem2: "Clean Code",
    },
    {
      boxicon: "css3",
      boxtitle: "CSS3",
      boxitem1link: "#",
      boxitem1: "UI/UX Experience",
      boxitem2link: "#",
      boxitem2: "SASS & LESS",
    },
    {
      boxicon: "vuejs",
      boxtitle: "Vue.js",
      boxitem1link: "#",
      boxitem1: "The best technology for your projects",
      boxitem2link: "#",
      boxitem2: "",
    },
    {
      boxicon: "node",
      boxtitle: "Node.js",
      boxitem1link: "#",
      boxitem1: "Rest API",
      boxitem2link: "#",
      boxitem2: "Desktop software with Electron",
    },
    {
      boxicon: "python",
      boxtitle: "Python",
      boxitem1link: "#",
      boxitem1: "Django & Flask",
      boxitem2link: "#",
      boxitem2: "Data analytics & Automation software",
    },
    {
      boxicon: "java",
      boxtitle: "Java",
      boxitem1link: "#",
      boxitem1: "Rest API",
      boxitem2link: "#",
      boxitem2: "Spring Boot",
    },
    {
      boxicon: "docker",
      boxtitle: "Docker",
      boxitem1link: "#",
      boxitem1: "Deploy your proyect with docker",
      boxitem2link: "#",
      boxitem2: "Deploy automation with Kubernetes",
    },
    {
      boxicon: "aws",
      boxtitle: "AWS",
      boxitem1link: "#",
      boxitem1: "Deploy your project in the cloud",
      boxitem2link: "#",
      boxitem2: "",
    },
    {
      boxicon: "wordpress",
      boxtitle: "Wordpress",
      boxitem1link: "#",
      boxitem1: "Woocommerce & Elementor",
      boxitem2link: "#",
      boxitem2: "Deploy your proyect with our hosting",
    },
  ],
};

export default TechStack;
