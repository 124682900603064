import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const userLang = navigator.language || navigator.userLanguage
const defaultLocale = userLang.substring(0, 2);

export const i18n = () => 
(
    i18next
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                global: {
                    //textos desde los mocks
                }
            },
            es: {
                global: {
                    //textos desde los mocks
                }
            }
        },
        lng: defaultLocale,
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        }
    })
)
